<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <b-modal ref="my-modal" hide-footer title="New Questionnaire" size="lg">
      <validation-observer ref="UploadForm" #default="{ invalid }">
        <b-form class="mt-2" @submit.prevent="handleNewQuestionnaireSubmit">
          <b-form-group label="Name">
            <template slot="label">
              Name <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="Name"
              rules="required"
            >
              <b-form-input v-model="newQuestionnaire.title" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Description">
            <template slot="label">
              Description <span class="text-danger">*</span>
            </template>
            <validation-provider
              #default="{ errors }"
              name="title"
              rules="required"
            >
              <b-form-textarea
                id="textarea"
                v-model="newQuestionnaire.description"
                rows="3"
                max-rows="6"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-button
            type="submit"
            variant="primary"
            class="mt-3 mr-1"
            :disabled="invalid"
          >
            <feather-icon icon="CheckIcon" class="mr-50" />
            Submit
          </b-button>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-sidebar
      lazy
      id="sidebar-backdrop"
      sidebar-class="sidebar-lg"
      bg-variant="white"
      backdrop
      right
      no-header
      shadow
    >
    </b-sidebar>
    <b-tabs content-class="mt-2" align="start">
      <b-tab title="Questionnaire Templates">
        <div class="card">
          <div class="card-body">
            <table role="table" class="table table-hover">
              <thead role="rowgroup">
                <tr role="row">
                  <th role="columnheader" scope="col">Name</th>
                  <th role="columnheader" scope="col">Description</th>
                  <th role="columnheader" scope="col">Created At</th>
                  <th role="columnheader" scope="col">Actions</th>
                </tr>
              </thead>
              <tbody role="rowgroup">
                <tr
                  v-for="item in questionnaire"
                  :key="item._id"
                  role="row"
                  class="cursor-pointer"
                  @click="hanldeQuestionnaireClick(item)"
                >
                  <td aria-colindex="2" role="cell" style="width: 220px">
                    <div
                      class="d-flex justify-content-start"
                      style="width: 220px"
                      v-b-tooltip.hover.top.v-secondary
                      :title="item.title"
                    >
                      <p class="font-weight-bolder mb-0 text-truncate">
                        {{ item.title }}
                      </p>
                    </div>
                  </td>

                  <td aria-colindex="2" role="cell" style="width: 220px">
                    <div
                      class="d-flex justify-content-start"
                      style="width: 220px"
                    >
                      <!-- <b-avatar variant="light-primary">
                    {{ item}}
                  </b-avatar> -->
                      <p
                        class="font-weight-bold text-truncate mb-0"
                        v-b-tooltip.hover.top.v-secondary
                        :title="item.description"
                      >
                        {{ item.description }}
                      </p>
                    </div>
                  </td>

                  <td aria-colindex="2" role="cell" style="width: 200px">
                    <div
                      class="d-flex justify-content-start"
                      style="width: 200px"
                    >
                      <p class="text-left font-weight-bold mb-0">
                        {{ item.created_at | moment }}
                      </p>
                    </div>
                  </td>
                  <td aria-colindex="2" role="cell">
                    <div
                      class="d-flex justify-content-start"
                      style="width: 150px"
                    >
                      <b-button
                        @click.stop="handleImportAction(item._id)"
                        variant="primary"
                        size="sm"
                        ><feather-icon icon="UploadIcon" class="mr-75" /><span
                          class="align-middle"
                          >Import</span
                        ></b-button
                      >
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="d-flex align-items-center justify-content-center w-100 py-2"
            >
              <b-pagination
                v-model="filters.page"
                :per-page="pagination.perPage"
                :total-rows="pagination.totalRows"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab title="My Questionnaires">
        <div class="card">
          <div class="card-header">
            <b-button variant="success" @click="addNewQuestionnaire"
              ><feather-icon icon="PlusIcon" class="mr-50" /> New
              Questionnaire</b-button
            >
          </div>
          <div class="card-body">
            <table role="table" class="table table-hover">
              <thead role="rowgroup">
                <tr role="row">
                  <th role="columnheader" scope="col">Name</th>
                  <th role="columnheader" scope="col">Description</th>
                  <th role="columnheader" scope="col">Created At</th>
                  <!-- <th role="columnheader" scope="col">Actions</th> -->
                </tr>
              </thead>
              <tbody role="rowgroup">
                <tr
                  v-for="item in importedQuestionnaires.data"
                  :key="item._id"
                  role="row"
                  class="cursor-pointer"
                  @click="handleMyQuestionnaireQuestionClick(item)"
                >
                  <td aria-colindex="2" role="cell" style="width: 220px">
                    <div
                      class="d-flex justify-content-start"
                      style="width: 220px"
                      v-b-tooltip.hover.top.v-secondary
                      :title="item.title"
                    >
                      <p class="font-weight-bolder mb-0 text-truncate">
                        {{ item.title }}
                      </p>
                    </div>
                  </td>

                  <td aria-colindex="2" role="cell" style="width: 220px">
                    <div
                      class="d-flex justify-content-start"
                      style="width: 220px"
                    >
                      <!-- <b-avatar variant="light-primary">
                    {{ item}}
                  </b-avatar> -->
                      <p
                        class="font-weight-bold text-truncate mb-0"
                        v-b-tooltip.hover.top.v-secondary
                        :title="item.description"
                      >
                        {{ item.description }}
                      </p>
                    </div>
                  </td>
                  <td aria-colindex="2" role="cell" style="width: 200px">
                    <div
                      class="d-flex justify-content-start"
                      style="width: 200px"
                    >
                      <p class="text-left font-weight-bold mb-0">
                        {{ item.created_at | moment }}
                      </p>
                    </div>
                  </td>
                  <!-- <td aria-colindex="2" role="cell">
                    <div
                      class="d-flex justify-content-start"
                      style="width: 150px"
                    >
                      <b-button
                        @click.stop="handleRemoveAction(item._id)"
                        variant="danger"
                        size="sm"
                        ><feather-icon icon="XIcon" class="mr-75" /><span
                          class="align-middle"
                          >Remove</span
                        ></b-button
                      >
                    </div>
                  </td> -->
                </tr>
              </tbody>
            </table>
            <div
              class="d-flex align-items-center justify-content-center w-100 py-2"
            >
              <b-pagination
                v-model="importedQuestionnairesFilters.page"
                :per-page="importedQuestionnaires.pagination.perPage"
                :total-rows="importedQuestionnaires.pagination.totalRows"
                aria-controls="my-table2"
              ></b-pagination>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
  </b-overlay>
</template>

<script>
import {
  BButton,
  BOverlay,
  BSidebar,
  BPagination,
  BAvatar,
  BTabs,
  BTab,
  BModal,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BForm,
} from "bootstrap-vue";
import AddVendorUserForm from "../components/AddVendorUserForm.vue";
import ResponseMixins from "../../../mixins/ResponseMixins";
import QuestionnaireMixins from "../../../mixins/QuestionnaireMixins";
import TaskMixins from "../../../mixins/TaskMixins";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import moment from "moment";

export default {
  components: {
    BButton,
    BSidebar,
    BOverlay,
    AddVendorUserForm,
    BPagination,
    BAvatar,
    BTabs,
    BTab,
    BModal,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BForm,
  },
  mixins: [ResponseMixins, QuestionnaireMixins, TaskMixins],
  data() {
    return {
      newQuestionnaire: {
        title: null,
        description: null,
      },
      showOverlay: false,
      vendorList: null,
      questionnaire: null,
      importedQuestionnaires: {
        data: null,
        pagination: {
          lastPage: 1,
          totalRows: 1,
          perPage: 10,
        },
      },
      importedQuestionnairesFilters: {
        page: 1,
        limit: 10,
      },
      pagination: {
        lastPage: 1,
        totalRows: 1,
        perPage: 10,
      },
      filters: {
        page: 1,
        limit: 10,
      },
    };
  },
  filters: {
    moment: function (date) {
      return moment(date).format("D-MMM-YY");
    },
  },
  mounted() {
    this.load();
  },
  watch: {
    filters: {
      handler: function (newValue) {
        this.getNonImportedQuestionnaireAndSetData(newValue);
      },
      deep: true,
    },
    importedQuestionnairesFilters: {
      handler: function (newValue) {
        this.getImportedQuestionnairesAndSetData(newValue);
      },
      deep: true,
    },
  },
  methods: {
    load() {
      this.getNonImportedQuestionnaireAndSetData(this.filters);
      this.getImportedQuestionnairesAndSetData(
        this.importedQuestionnairesFilters
      );
    },
    addNewQuestionnaire() {
      this.newQuestionnaire = {
        title: null,
        description: null,
      };
      this.showModal();
    },
    handleNewQuestionnaireSubmit() {
      this.showOverlay = true;
      this.createCustomQuestionnaire(this.newQuestionnaire)
        .then((res) => {
          this.handleResponse(res);
          this.load();
          this.hideModal();
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    showModal() {
      this.$refs["my-modal"].show();
    },

    handleMyQuestionnaireQuestionClick(questionnaire) {
      this.$router.push(
        `/imported-questionnaires/${questionnaire._id}/details`
      );
    },

    handleImportAction(id) {
      this.importNonImportedQuestionnaire(id)
        .then((res) => {
          this.handleResponse(res);
          this.load();
        })
        .catch((err) => {
          this.handleError(err);
        });
    },

    handleRemoveAction(id) {
      this.removeImportedQuestionnaire(id)
        .then((res) => {
          this.handleResponse(res);
          this.load();
        })
        .catch((err) => {
          this.handleError(err);
        });
    },

    hanldeQuestionnaireClick(questionnaire) {
      this.$router.push(`/questionnaires/${questionnaire._id}/details`);
    },

    getImportedQuestionnairesAndSetData(filters) {
      this.getImportedQuestionnaires(filters)
        .then((res) => {
          this.importedQuestionnaires.data = res.data.data.data;
          this.importedQuestionnaires.pagination.lastPage =
            res.data.data.last_page;
          this.importedQuestionnaires.pagination.totalRows =
            res.data.data.total;
          this.importedQuestionnairesFilters.page = res.data.data.current_page;
          this.importedQuestionnairesFilters.limit = res.data.data.per_page;
        })
        .catch((err) => {
          this.handleError(err);
        });
    },

    getNonImportedQuestionnaireAndSetData(filters) {
      this.showOverlay = true;
      this.getNonImportedQuestionnaire(filters)
        .then((res) => {
          this.questionnaire = res.data.data.data;
          this.pagination.lastPage = res.data.data.last_page;
          this.pagination.totalRows = res.data.data.total;
          this.filters.page = res.data.data.current_page;
          this.filters.limit = res.data.data.per_page;
        })
        .catch((err) => {
          this.handleError(err);
        })
        .finally(() => {
          this.showOverlay = false;
        });
    },

    closeSidebar: function () {
      this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
    },
    openSidebar: function () {
      this.$nextTick().then(() => {
        this.$root.$emit("bv::toggle::collapse", "sidebar-backdrop");
      });
    },
  },
};
</script>


<style lang="css">
.b-sidebar.sidebar-lg {
  width: 60rem !important;
}
</style>